<template>
  <div class="bookmaster-wraper">
    <app-modal-confirm
      ref="apikey_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteApiKey()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="restaurant_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteNewsCategory()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >

      <form class="form-horizontal">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <template v-if="idEditing">
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
                  <label class="col-xl-1 col-lg-2 col-sm-2 m-t-8">{{ entry.id }}</label>
                </div>
              </template>

              <!-- Restaurant name JA-->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("news_category.name") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="restaurant_name"
                    type="string128"
                    rules="required"
                    v-model="entry.name"
                  />
                </div>
              </div>

              <!-- Restaurant name VN -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("news_category.name_vn") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="restaurant_name"
                    type="string128"
                    rules="required"
                    @input="updateSlugField"
                    v-model="entry.name_vn"
                  />
                </div>
              </div>

              <!-- Slug -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common_vn.slug") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="slug"
                    type="string128"
                    v-model="entry.slug"
                  />
                </div>
              </div>

              <!-- Meta description -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common_vn.meta_description") }}</label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-textarea
                    name="meta_description"
                    type="string128"
                    v-model="entry.meta_description"
                  />
                </div>
              </div>
              <div class="card-footer row justify-content-end">
                <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
                  {{ $t('common_vn.cancel') }}
                </button>
                <button v-if="idEditing" type="button" :disabled="!valid && validated" class="btn btn-danger m-r-10" @click="handleDelete">
                  {{ $t('common_vn.delete') }}
                </button>
                <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary m-r-10" @click="createOrUpdate" type="button">
                  {{  $t('common_vn.save') }}
                </button>
                <button v-else :disabled="!valid && validated" class="btn btn-primary m-r-10" type="button" @click="createOrUpdate" >
                  {{  $t('common_vn.create') }}
                </button>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import AppTextarea from "../../../components/forms/AppTextarea";
import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
import {ROUTES} from "@constants";
import {convertToSlug} from "@utils";
export default {
  name: "NewsCategoryCreateOrEdit",

  components: {
    AppTextarea,
    AppDateRangePicker,
  },

  data() {
    const id = this.$route.params.id
    return {
      entry: {
        id: null,
        name: '',
        name_vn: '',
        slug: '',
        meta_description: '',
        data_updated_at: null,
      },
      apiKeyId: null,
      idEditing: id,
      entryNameUpdate: "",
      startValidate : !!id,
    };
  },
  watch: {
  },

  mounted: async function () {
    if (this.idEditing) {
      this.startValidate = false;
      let response = await this.$request.get(this.ENDPOINT.KYY_NEWS_CATEGORY_EDIT(this.idEditing));
      if (response.hasErrors()) {
        if (response.status === 'error'){
          this.__noticeError(this.$t(response.data.error_msg));
        }
        await this.redirectDefaultValue()
      } else {
        this.entry = _.cloneDeep(response.data.data);
        this.startValidate = true;
      }
    }
  },

  methods: {
    handlerUploadImage(data) {
      this.entry.image = data
      this.entry.image_path = data
      this.entry.image_updated_at = new Date().getTime()
    },
    confirmCancel()
    {
      if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.BOOK_MASTER_LIST) {
        this.__redirectBack()
      } else {
        this.redirectDefaultValue()
      }
    },
    async createOrUpdate() {
      this.startValidate = true;
      if (!await this.$refs.form_create_entry.validate()) {
        return;
      }
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      if (this.idEditing) {
        res = await this.$request.patch(this.ENDPOINT.KYY_NEWS_CATEGORY_EDIT(this.idEditing), params);
        msg = this.$t("common.msg update ok")
      } else {
        res = await this.$request.post(this.ENDPOINT.KYY_NEWS_CATEGORY_CREATE, params);
        msg = this.$t("common.msg create ok")
      }
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.__noticeSuccess(msg);
        await this.redirectDefaultValue();
      }
    },

    async redirectDefaultValue() {
      await this.$router.push({
        name: this.ROUTES.ADMIN.KYY_NEWS_CATEGORY_LIST, query: {}
      });
    },
    handleDelete() {
      this.$refs.restaurant_modal_delete.show();
    },
    async deleteNewsCategory() {
      const res = await this.$request.delete(this.ENDPOINT.KYY_NEWS_CATEGORY_DELETE(this.idEditing));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        await this.redirectDefaultValue();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        this.$refs.restaurant_modal_delete.hide();
      }
    },
    updateSlugField() {
      this.entry.slug = convertToSlug(this.entry.name_vn)
    }
  },
};
</script>
<style>
@media (min-width: 1200px) {
  .col-unit {
    flex: 0 0 14%;
  }
}

</style>
